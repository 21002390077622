import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { RegxConstant } from 'src/app/shared/class/regx';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { EmailSendScreen } from '../../error-message/emailSend-screen';
import { AcmeProductService } from '../../service/acme-product.service';

@Component({
  selector: 'app-acme-email-send',
  templateUrl: './acme-email-send.component.html',
  styleUrls: ['./acme-email-send.component.css']
})
export class AcmeEmailSendComponent implements OnInit {
  @Input() WBAid: any;
  sendEmailForm: any;
  _regxConstant: RegxConstant;
  _navigationPath: NavigationPath;
  _AddprospectScreen: EmailSendScreen;
  submitted: boolean = false;
  showError: boolean = false;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  ToInvalid: boolean = false;
  saveReq: any;
  csvFileName: any;
  items: any;
  errorMessage: any;
  errorList: any[] = [];
  successFullymsg: any;
  isProceed: boolean = false;
  fileDetails: any;
  isImport: boolean = false;
  columnUploaded: any = [];
  filesToUpload: any;
  mailSuccess: boolean = false;
  _userEmailMessages: UserMessages;
  constructor(
    private fb: UntypedFormBuilder,
    private _AcmeProductService: AcmeProductService,
    public activeModal: NgbActiveModal,
    private _router: Router,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
  ) {
    this._navigationPath = new NavigationPath();
    this._AddprospectScreen = new EmailSendScreen();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._userEmailMessages = new UserMessages()
    this._regxConstant = new RegxConstant();
    this.sendEmailForm = this.fb.group({
      To: ['', [Validators.pattern(this._regxConstant.Email()), Validators.required]],
      Body: [''],
      Subject: [''],
      Attachment: ['']
    })
  }

  ngOnInit(): void {
    if (this.WBAid) {
    }
  }

  //send email
  sendEmail() {

    this.submitted = true;
    let data = this.sendEmailForm.getRawValue();
    const formData = new FormData();
    formData.append('postedfile', this.filesToUpload);
    if (this.sendEmailForm.invalid) {
      return
    }
    else {
      let saveData = {
        "To": data.To,
        "Body": data.Body,
        "Subject": "[WBA-" + this.WBAid + "] : " + data.Subject,
        "postedfile": formData
      }
      this.saveReq = saveData
      this._AcmeProductService.getAcmeSendEmail(this.saveReq).subscribe((resp: any) => {
        if (resp) {
          this.mailSuccess = true
          this._confirmBox.message = this._userEmailMessages.confirmationEmailMessage;
          this._confirmBox.isVisible = true;
        }
      },
        (err: any) => {
          this.showError = true;
          if (err.status !== 200) {
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorList = [];
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            } else {

              this.showError = true;
              this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
              this.errorList.push(this.errorMessage);
            }
          }
        });
    }

  }

  // Remove white spaces from every input fileds
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  //to cancel the process and back to Acme Product list screen
  cancel() {
    if (this.sendEmailForm.dirty) {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
    else {
      this.activeModal.close();
    }
  }

  //confirm box cancel method
  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }
  closeModal() {
    this.activeModal.close();
  }
  confirmationEmailBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  //to get all form controls
  get f() {
    return this.sendEmailForm.controls;
  }


  closeError() {
    this.showError = false;
  }

  fileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.csvFileName = '';
      this.successFullymsg = '';
      this.isProceed = true;
      this.isImport = false;
      this.columnUploaded = [];
      const file = event.target.files[0];
      this.filesToUpload = file;
      this.fileDetails = event.target.files[0].name
      const formData = new FormData();
      formData.append('postedfile', this.filesToUpload);

    }
  }
}


