export class EmailSendScreen {

    To = {
        pattern: 'Invalid Email Address.',
        required: 'To is required .',
        maxlength: '“Email Address should not exceed more than 60 characters.'
    }
    name = {
        required: "Name is required"
    }


}


