import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dataRestIcon } from '@progress/kendo-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IacmeProductDetail } from '../interface/iacme-product-detail';

@Injectable({
  providedIn: 'root'
})
export class AcmeProductService {
  public notifyData = new BehaviorSubject({})
  private __createAcmeUrl = `${environment.BaseUrl}/api/AcmeProduct/Create`;
  private __createAcmeProductDetailUrl = `${environment.BaseUrl}/api/AcmeProductDetail/Create`;
  private __acmeProductDetails = `${environment.BaseUrl}/api/AcmeProduct/`;
  private __acmeProductListDetails = `${environment.BaseUrl}/api/AcmeProductDetail/`;
  private __updateAcmeProduct = `${environment.BaseUrl}/api/AcmeProduct/`;
  private __updateAcmeProductDetail = `${environment.BaseUrl}/api/AcmeProductDetail/`;
  private _permissionListUrl = `${environment.BaseUrl}/api/Auth/Permissions`;
  private _acmeProductListUrl = `${environment.BaseUrl}/api/AcmeProduct`;
  private _deleteAcmeProductUrl = `${environment.BaseUrl}/api/AcmeProduct/`;
  private _deleteAcmeProductDetailUrl = `${environment.BaseUrl}/api/AcmeProductDetail/`;
  private _exportAcmeProductListUrl = `${environment.BaseUrl}/api/AcmeProduct/ExportAcmeProductDetailList`;
  private _acmeEmailListUrl = `${environment.BaseUrl}/api/AcmeEmail/GetAcmeEmailList`;
  private _acmeEmailSendUrl = `${environment.BaseUrl}/api/CaffeineEmail/SendEmailFromCaffeine?to=`;
  private __acmeEmailDetails = `${environment.BaseUrl}/api/AcmeEmail/GetAcmeEmailByMessageId?messageId=`;
  private _acmeProductDetailListUrl = `${environment.BaseUrl}/api/AcmeProductDetail/GetAcmeProductDetailListByAcmeProductId/`;
  private _importAcmeProductUrl = `${environment.BaseUrl}/api/AcmeProduct/ImportAcmeProductDetailListFromCsv`;
  private _usersUrl = `${environment.BaseUrl}api/User/GetUsers`;
  private _userRoleUrl = `${environment.BaseUrl}/api/Auth/Roles`;
  private _userPermissionUrl = `${environment.BaseUrl}/api/User/GetPermissionGroups`;
  private _rolePermissionById = `${environment.BaseUrl}/api/User/GetPermissionsByRoleId?roleId=`;
  private _rolePermissionSave = `${environment.BaseUrl}/api/User/AddPermissionsForRole`;
  private _userPermissionGrantSaveUrl = `${environment.BaseUrl}api/User/AddPermissionsGrantedForUser`;
  private _userDetailsUrl = `${environment.BaseUrl}/api/User/GetUserById?userId=`;
  private _userPermissionDeniedSaveUrl = `${environment.BaseUrl}/api/User/AddPermissionsDeniedForUser`;
  private _userRolesSaveUrl = `${environment.BaseUrl}/api/User/AddRoles`;
  private _emailListByEntityUrl = `${environment.BaseUrl}/api/AcmeEmail/GetAcmeEmailByEntity?entityType=`

  constructor(private _http: HttpClient) { }

  //Create Acme Product
  createAcmeProduct(data: any) {
    return this._http.post(this.__createAcmeUrl, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //To get acme product  detail
  getAcmeProductDetail(acmeProductId: any) {
    return this._http.get<IacmeProductDetail>(this.__acmeProductDetails + acmeProductId);
  }

  //To get acme email  detail
  getAcmeEmailDetail(massageId: any) {

    return this._http.get<any>(this.__acmeEmailDetails + massageId);
  }

  //to update Acme Product
  updateAcmeProduct(data: any) {
    return this._http.put(this.__updateAcmeProduct + data.id, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  getPermissionList() {
    return this._http.get(this._userPermissionUrl).pipe(
      map((resp: any) => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  //get acme email list
  getAcmeEmailList() {
    return this._http.get<any>(this._acmeEmailListUrl);
  }

  //get acme email list according to entity
  getAcmeEmailListByEntity(entity: any, id: any) {
    return this._http.get<any>(this._emailListByEntityUrl + entity + '&entityId=' + id);
  }

  //get acme product list
  getAcmeProductList() {
    return this._http.get<any>(this._acmeProductListUrl);
  }

  //to delete Acme product
  deleteAcmeProduct(id: any) {
    return this._http.delete(this._deleteAcmeProductUrl + id)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //to delete Acme product detail
  deleteAcmeProductDetail(id: any) {
    return this._http.delete(this._deleteAcmeProductDetailUrl + id)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //export the file
  public downloadFile(): Observable<any> {
    return this._http.get(this._exportAcmeProductListUrl,
      { observe: 'response', responseType: 'blob' })
  }

  //get acme product detail list
  getAcmeProductDetailList(acmeProductId: any) {
    return this._http.get<any>(this._acmeProductDetailListUrl + acmeProductId);
  }

  //Create Acme Product
  createAcmeProductDetail(data: any) {
    return this._http.post(this.__createAcmeProductDetailUrl, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //To get acme product List detail
  getAcmeProductListDetail(acmeProductDetailId: any) {
    return this._http.get<any>(this.__acmeProductListDetails + acmeProductDetailId);
  }

  //to update Acme Product
  updateAcmeProductDetail(data: any) {
    return this._http.put(this.__updateAcmeProductDetail + data.id, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //get acme send email
  getAcmeSendEmail(data: any) {

    return this._http.post(this._acmeEmailSendUrl + data.To + '&mailbody=' + data.Body + '&subject=' + data.Subject, data.postedfile)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )

  }

  // Import Acme Product
  importAcmeProduct(postedFiles: any) {
    return this._http.post(this._importAcmeProductUrl, postedFiles)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //get user list
  getUsersList() {
    return this._http.get<any>(this._usersUrl);
  }

  //get role list
  getRoleList() {
    return this._http.get<any>(this._userRoleUrl);
  }

  //get permission by id
  getRolepermission(roleId: any) {
    return this._http.get<any>(this._rolePermissionById + roleId);
  }

  //to save role permission
  rolePermission(data: any) {
    return this._http.post(this._rolePermissionSave, data).pipe(
      map((resp: any) => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  //to save Grant permission
  craetePermission(data: any) {
    return this._http.post(this._userPermissionGrantSaveUrl, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //get user by id
  getUserById(id: any) {
    return this._http.get<any>(this._userDetailsUrl + id);
  }

  //to save denied permission
  craeteDeniedPermission(data: any) {
    return this._http.post(this._userPermissionDeniedSaveUrl, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //to save roles permission
  craeteRoles(data: any) {
    return this._http.post(this._userRolesSaveUrl, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }
}
