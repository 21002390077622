<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Email</h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
                <div class="row" *ngIf="showError">
                    <div class="col-12">
                        <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                            <ul class="view-error">
                                <li *ngFor="let err of errorList">{{err}}</li>
                            </ul>
                            <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-content add-form">
                <div class="pdg-con">
                    <form class="row g-3">
                        <div class="add-form">
                            <div class="add-fld ">

                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12">

                                        <!-- <div class="add-title">
                                            <h3>Send Email</h3>
                                        </div> -->
                                        <div class="row">
                                            <form class="add-noti-form row" method="post" [formGroup]="sendEmailForm">
                                                <div class="row frmrow">
                                                    <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                        <label class="form-label">To : <span class='star-color'>*</span>
                                                        </label>

                                                        <input name="To"
                                                            [class.errorInput]="(f.To.invalid || ToInvalid)  && submitted"
                                                            class="form-control" formControlName="To"
                                                            (focusout)="removeSpaces($event,f.To)" id="To"
                                                            placeholder="" />
                                                        <div class="display-error" *ngIf="f.To.invalid && submitted">
                                                            <span *ngIf="f.To.errors?.pattern">
                                                                {{_AddprospectScreen.To.pattern}}
                                                            </span>
                                                            <span *ngIf="f.To.errors?.required">
                                                                {{_AddprospectScreen.To.required}}
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                        <label class="form-label">Subject : <span></span>
                                                        </label>

                                                        <input name="subject" class="form-control"
                                                            formControlName="Subject" id="Subject" placeholder="" />
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                        <label class="form-label">Body : <span></span>
                                                        </label>
                                                        <textarea name="from" class="form-control"
                                                            formControlName="Body" id="Body" placeholder=""></textarea>
                                                    </div>

                                                    <!-- <angular-file-uploader [config]="afuConfig"
                                                        (fileSelected)="fileSelected($event)">
                                                    </angular-file-uploader> -->
                                                    <div class="col-12 col-sm-12 col-md-12 mt-3 mb-1">
                                                        <div class="upload-block file-upload-wrapper">
                                                            <div class="file-drop-area">
                                                                <span class="flex-div justify-center upl"><img
                                                                        src="assets/images/upload-file.svg" width="30px"
                                                                        alt="" /></span>

                                                                <span class="file-message small">Click For
                                                                    Attachment.</span>

                                                                <input id="file" class="file-input" #fileUpload
                                                                    type="file" (change)="fileSelected($event)" />

                                                            </div>
                                                            <div style="text-align: center">
                                                                <span *ngIf="filesToUpload">
                                                                    Attached name : {{ filesToUpload.name }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>

                </div>
                <div class="blk-bt">
                    <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
                    <button class="btn btn-primary ms-2 me-4" (click)="sendEmail()">Send</button>

                </div>
                <!-- Dialog for confirmation -->
                <p-dialog [header]="!mailSuccess ? _confirmBox.header : _confirmBox.header2" [modal]="true"
                    [(visible)]="_confirmBox.isVisible" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
                    [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                    <p>{{_confirmBox.message}}</p>
                    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                        <p-button *ngIf="!mailSuccess" (click)="_confirmBox.isVisible = false" label="No"
                            styleClass="btn btn-outline-primary">
                        </p-button>
                        <p-button *ngIf="!mailSuccess" (click)="confirmBoxCancel()" label="Yes"
                            styleClass="btn btn-primary"></p-button>
                        <p-button *ngIf="mailSuccess" (click)="confirmationEmailBoxCancel()" label="OK"
                            styleClass="btn btn-primary"></p-button>
                    </ng-template>
                </p-dialog>

            </div>

        </div>
    </div>
</div>